<template>
  <ThemeVBtn
    v-bind="$attrs"
    @click="(e) => $emit('click', e)"
    :width="width"
    :height="height"
    min-width="40px"
  >
    <div class="flex items-center justify-center p-2px">
      <v-icon size="20" class="!text-inherit"><slot></slot></v-icon>
    </div>
  </ThemeVBtn>
</template>
<script>
import ThemeVBtn from '@/components/global/c-vuetify/button/ThemeVBtn'
export default {
  components: { ThemeVBtn },
  props: {
    width: {
      type: String,
      default: '40px',
    },
    height: {
      type: String,
      default: '40px',
    },
  },
}
</script>
