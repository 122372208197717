<template>
  <div class="al-table_pagination-default_container">
    <component
      :is="paginationComponent"
      ref="paginationComponent"
      :totalPage="totalPage"
      :min="displayAllLimit"
      :page="page"
      @changePage="changePage"
    />
  </div>
</template>

<script>
export default {
  props: {
    totalPage: {
      type: Number,
      default: 1,
    },
    // 幾頁以內（包含）頁數全顯示（不使用省略號）
    displayAllLimit: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    isEllipsis() {
      return this.totalPage > this.count
    },
    paginationComponent() {
      if (this.isEllipsis) {
        return () => import('./PaginationNumber1Ellipsis.vue')
      }
      return () => import('./PaginationNumber1All.vue')
    },
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    },
    gotoPageOne() {
      this.$refs.paginationComponent.gotoPageOne()
    },
  },
}
</script>
<style lang="scss">
.al-table_pagination-default_container {
  display: flex;
  align-items: center;
  height: 54px;
  justify-content: center;
}

.al-table_pagination-default_button {
  border: none;
  color: $color-grey-700;
  background-color: $color-grey-100;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 12px;
  line-height: 24px;
  transition: all 0.3s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  &.al-table_pagination-default_current-page {
    background-color: $color-primary-600;
    color: $color-grey-100;
  }
}
.al-table_pagination-default_hover-text:not(.al-table_pagination-default_current-page) {
  transition: all 0.2s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  &:hover {
    color: $color-primary-600;
  }
}

.al-table_pagination-default_hover-button {
  transition: all 0.2s cubic-bezier(0.15, 0.91, 0.47, 0.96);
  &:hover {
    background-color: $color-primary-600;
    color: $color-grey-100;
  }
}
.al-table_pagination-default_page-container {
  background-color: $color-grey-100;
  border-radius: 20px;
  display: flex;
}
</style>
