<template>
  <div
    class="al-table_container bg-al-grey-100 max-w-full"
    :style="{ minWidth }"
  >
    <div class="al-table_toolbar-container">
      <slot name="toolbar"></slot>
    </div>
    <TableBody
      :columns="columns"
      :datas="datas"
      class="al-table_border"
      :isLoading="isLoading"
    >
      <template #header-cell="{ column, isId }">
        <slot name="header-cell" :column="column" :isId="isId"></slot>
      </template>
      <template #cell="{ column, data, isId }">
        <slot name="cell" :column="column" :data="data" :isId="isId"></slot>
      </template>
    </TableBody>
    <PaginationDefault
      ref="paginationComponent"
      :page="page"
      :totalPage="totalPage"
      @changePage="(e) => $emit('changePage', e)"
    />
  </div>
</template>
<script>
import TableBody from './TableBody'
import PaginationDefault from './PaginationDefault'
export default {
  components: {
    TableBody,
    PaginationDefault,
  },
  props: {
    minWidth: {
      type: String,
      default: '800px',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Array,
      default: () => [],
    },
    totalPage: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * for parent using
     */
    gotoPageOne() {
      this.$refs.paginationComponent.gotoPageOne()
    },
  },
}
</script>
<style lang="scss" scoped>
.al-table_container {
  overflow: hidden;
  border-radius: 16px;
  border: 1px solid $color-grey-400;
}
.al-table_toolbar-container {
  padding: 14px 16px;
  min-height: 68px;
  width: 100%;
}
</style>
