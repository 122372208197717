<template>
  <div :class="width === undefined ? 'w-full' : undefined" :style="{ width }">
    <div v-if="!hideLabelPlace" class="label">
      <AlText class="text-al-grey-800" size="12">{{ label }}</AlText>
      <span v-if="required && label" class="require-mark">*</span>
    </div>
    <!-- height 因為 vuetify select 的高度會包含 border 所以高度加上 2px(1px+1px) -->
    <VSelect2
      :class="['!p-0 !m-0', selectClass]"
      v-bind="$attrs"
      @change="(e) => $emit('input', e)"
      @blur="(e) => $emit('blur', e)"
      @focus="(e) => $emit('focus', e)"
      :height="dense ? '42px' : '50px'"
      dense
      hide-details
      outlined
    ></VSelect2>
    <div v-if="!hideDetails" class="helper-text" :class="extendClass">
      <span v-if="helperText">{{ helperText }}</span>
    </div>
  </div>
</template>
<script>
import AlText from '@/components/global/also-ui/AlText.vue'
// eslint-disable-next-line no-unused-vars
const themeEnum = {
  default: 'default',
  error: 'error',
}
export default {
  inheritAttrs: false,
  components: {
    AlText,
  },
  props: {
    // 上方標題
    label: {
      type: String,
      default: '',
    },
    // 是否必填（是的話若有標題，標題旁多出紅色星號）
    required: {
      type: Boolean,
      default: false,
    },
    // 幫助文字（提示 or 錯誤訊息）
    helperText: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    // 高密度
    dense: {
      type: Boolean,
      default: false,
    },
    // 隱藏 label 區塊
    hideLabelPlace: {
      type: Boolean,
      default: false,
    },
    selectClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    theme() {
      if (this.isError) return themeEnum.error
      return themeEnum.default
    },
    // for vue dynamic class
    extendClass() {
      switch (this.theme) {
        case themeEnum.error: {
          return { 'is-error': true }
        }
        case themeEnum.default:
        default: {
          return ''
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.label {
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
  @include font('xs');
  @include height-by-size('xs');
}

.require-mark {
  color: $color-error;
}

.helper-text {
  @include font('xs');
  color: $color-warning;
  width: 100%;
  height: 16px;
  margin-top: 4px;
  text-align: left;
  &.is-error {
    color: $color-error;
  }
}
</style>
<style lang="scss">
/* override */
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: $color-grey-600;
}

.v-text-field .v-input__slot {
  border-radius: 6px;
}

.v-select__selection {
  @include font('base');
  color: $color-grey-800;
}
</style>
