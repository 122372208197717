<template>
  <DefaultVDialog
    v-bind="$attrs"
    :value="value"
    @input="(e) => $emit('input', e)"
    large-padding
  >
    <AlText class="font-semibold text-al-grey-900 text-center" size="18" block>
      {{ title }}
    </AlText>
    <slot name="top"></slot>
    <div
      :class="noTopMargin ? '' : 'mt-16px'"
      class="p-16px bg-al-grey-200 overflow-auto rounded-8px text-left"
      style="height: 392px"
      @scroll.passive="scrollHandler"
      ref="mainContent"
    >
      <div class="w-full" ref="elementListenResize">
        <slot></slot>
      </div>
    </div>
    <slot name="bottom"></slot>
    <AlText
      :class="noBottomMargin ? '' : 'mt-16px'"
      class="text-al-grey-700 text-center"
      size="14"
      block
    >
      請滑動至最下方查看完整內容
    </AlText>
    <div class="text-center">
      <ThemeVBtn
        class="mt-8px"
        :disabled="!isScrolledToBottom"
        @click="$emit('confirm')"
      >
        我已閱讀且同意
      </ThemeVBtn>
    </div>
  </DefaultVDialog>
</template>
<script>
import _debounce from 'lodash/debounce'
import DefaultVDialog from '@/components/global/c-vuetify/dialog/DefaultVDialog'
export default {
  components: { DefaultVDialog },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    noTopMargin: {
      type: Boolean,
      default: false,
    },
    noBottomMargin: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newValue) {
      // open
      if (newValue) {
        this.isScrolledToBottom = false
        // dialog 展開前定位不到，所以須等展開
        this.$nextTick(() => {
          this.$refs.mainContent.scrollTop = 0
          this.resizeObserve = new ResizeObserver(this.resizeHandler)
          this.resizeObserve.observe(this.$refs.elementListenResize)
        })
      }
    },
  },
  data() {
    return {
      isScrolledToBottom: false,
      resizeObserve: undefined,
    }
  },
  methods: {
    resizeHandler() {
      console.log('resizeHandler')
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.mainContent
      console.log({ scrollTop, clientHeight, scrollHeight })
      // TODO: change to new flow
      const isScrollToBottom = scrollTop + clientHeight >= scrollHeight - 1
      this.isScrolledToBottom = isScrollToBottom
    },
    scrollHandler: _debounce(function (e) {
      console.log('scrollHandler')
      const { scrollTop, clientHeight, scrollHeight } = e.target
      console.log({ scrollTop, clientHeight, scrollHeight })
      // TODO: change to new flow
      const isScrollToBottom = scrollTop + clientHeight >= scrollHeight - 1
      this.isScrolledToBottom = isScrollToBottom
    }, 200),
  },
  beforeDestroy() {
    this.resizeObserve?.unobserve(this.$refs.elementListenResize)
  },
}
</script>
