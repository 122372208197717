var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"al-table_container bg-al-grey-100 max-w-full",style:({ minWidth: _vm.minWidth })},[_c('div',{staticClass:"al-table_toolbar-container"},[_vm._t("toolbar")],2),_c('TableBody',{staticClass:"al-table_border",attrs:{"columns":_vm.columns,"datas":_vm.datas,"isLoading":_vm.isLoading},scopedSlots:_vm._u([{key:"header-cell",fn:function(ref){
var column = ref.column;
var isId = ref.isId;
return [_vm._t("header-cell",null,{"column":column,"isId":isId})]}},{key:"cell",fn:function(ref){
var column = ref.column;
var data = ref.data;
var isId = ref.isId;
return [_vm._t("cell",null,{"column":column,"data":data,"isId":isId})]}}],null,true)}),_c('PaginationDefault',{ref:"paginationComponent",attrs:{"page":_vm.page,"totalPage":_vm.totalPage},on:{"changePage":function (e) { return _vm.$emit('changePage', e); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }