import { middlewareLogin } from '@/middleware/router/auth'
export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      middleware: {
        middlewaresBeforeEach: [middlewareLogin],
      },
      title: '登入',
      isPublic: true,
    },
    component: () =>
      import(/* webpackChunkName: "page-login" */ '@/views/login.vue'),
  },
]
