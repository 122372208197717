import store from '@/store'

// eslint-disable-next-line no-unused-vars
const middlewareUpdate = async ({ to, from }) => {
  if (
    // 檢查用戶是否已登入
    !store.state.auth.activeUser &&
    // 避免無限重定向
    !to.meta?.isPublic
  ) {
    // const activeUser = await getActiveUser()
    // store.commit('auth/setActiveUser', activeUser)
    console.log('not active user')
    try {
      await store.dispatch('auth/initActiveUser')
    } catch (error) {
      console.log(error)
    }
    console.log('store.state.auth.activeUser', store.state.auth.activeUser)
    if (!store.state.auth.activeUser) {
      console.log('update user error')
      return { name: 'login' }
    }
    return true
  }
}

/**
 * if user is login, will reject then go to index page.
 * @param {*} param0
 * @returns
 */
// eslint-disable-next-line no-unused-vars
const middlewareLogin = async ({ to, from }) => {
  if (
    // 檢查用戶是否已登入
    !store.state.auth.activeUser &&
    // 避免無限重定向
    to.meta?.isPublic
  ) {
    try {
      await store.dispatch('auth/initActiveUser')
    } catch (error) {
      console.log(error)
    }
    if (!store.state.auth.activeUser) return true
    return { name: 'index' }
  }
}

// eslint-disable-next-line no-unused-vars
const middlewarePermission = async ({ to, from }) => {
  if (
    !to.meta?.permission ||
    store.state.auth.activeUser?.permission.find(
      (item) => to.meta.permission[item]
    )
  ) {
    return true
  } else {
    return false
  }
}
export { middlewareLogin, middlewareUpdate, middlewarePermission }
