<template>
  <AlInput
    @blur="(e) => $emit('blur', e)"
    @focus="(e) => $emit('focus', e)"
  ></AlInput>
</template>
<script>
// this component is not finish
import AlInput from '@/components/global/also-ui/AlInput'
import typeEnum from '@/components/global/base/input/InputDefault/typeEnum'
const { theme: themeEnum } = typeEnum
export default {
  inheritAttrs: false,
  components: {
    AlInput,
  },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    // 輸入值，若有提供 validate，會在 blur 時檢查
    value: {
      type: String,
      default: '',
    },
    // (value) => Boolean
    // 驗證 value 是否錯誤的方法，若無提供則不驗證
    validate: {
      type: Function,
      default: undefined,
    },
    // 提示文字（isError is false 才會顯示，與 ）
    helperText: {
      type: String,
      default: '',
    },
    // 錯誤時顯示的提示文字
    errorText: {
      type: String,
      default: '',
    },
  },
  computed: {
    themeName() {
      if (this.isError) return themeEnum.error
      return themeEnum.default
    },
  },
  methods: {
    focusHandler() {
      // doing cancel error and emit focus
    },
    blurHandler() {
      // doing validate detect and emit blur
    },
  },
}
</script>
