<template>
  <DefaultVCheckbox
    v-model="isChecked"
    v-bind="$attrs"
    boolean
    @input="(e) => $emit('input', e)"
    @click="(e) => $emit('click', e)"
  />
</template>
<script>
import DefaultVCheckbox from '@/components/global/c-vuetify/checkbox/DefaultVCheckbox'
export default {
  components: {
    DefaultVCheckbox,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isChecked() {
      this.$nextTick(() => {
        this.isChecked = this.value
      })
    },
    value(newValue) {
      this.$nextTick(() => {
        this.isChecked = newValue
      })
    },
  },
  data() {
    return {
      isChecked: false,
    }
  },
  mounted() {
    this.isChecked = this.value
  },
}
</script>
