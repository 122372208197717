<template>
  <div class="al-table_table-header_container bg-al-grey-200">
    <div
      v-for="(column, columnIndex) in columns"
      :key="`al-table_table-header_column-${columnIndex}`"
      :style="{ width: column.width }"
      class="px-16px py-12px flex items-center justify-start"
    >
      <slot name="header-cell" :column="column" :isId="isId(column.id)">
        <AlText class="font-semibold text-al-grey-800 text-left" size="14">
          {{ column.title }}
        </AlText>
      </slot>
    </div>
  </div>
</template>
<script>
import { isId } from '../alTableUtils'
import AlText from '@/components/global/also-ui/AlText'
export default {
  components: { AlText },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  methods: { isId },
}
</script>
<style scoped>
.al-table_table-header_container {
  display: flex;
  justify-content: space-between;
  height: 46px;
}
</style>
