<template>
  <div class="flex">
    <button
      class="al-table_pagination-default_button mr-5px al-table_pagination-default_hover-button"
      @click="goToPrevious"
    >
      <div class="flex p-2px items-center justify-center">
        <v-icon size="20" class="!text-inherit">chevron_left</v-icon>
      </div>
    </button>
    <div class="al-table_pagination-default_page-container">
      <button
        v-for="(pageNum, index) in pageDisPlayViewModels"
        :key="`pagination-number-1-${index}`"
        class="al-table_pagination-default_button al-table_pagination-default_hover-text"
        :class="{
          'al-table_pagination-default_current-page': pageNum === currentPage,
        }"
        @click="changePage(pageNum)"
      >
        {{ pageNum }}
      </button>
    </div>
    <button
      class="al-table_pagination-default_button ml-5px al-table_pagination-default_hover-button"
      @click="goToNext"
    >
      <div class="flex p-2px items-center justify-center">
        <v-icon size="20" class="!text-inherit">chevron_right</v-icon>
      </div>
    </button>
  </div>
</template>
<script>
// _range(1,3) will return [1,2]
import _range from 'lodash/range'

const START_PAGE = 1
// 中間頁數(奇數)
const CENTER_PAGE_COUNT = 5
const ELLIPSIS = '...'
export default {
  props: {
    totalPage: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  watch: {
    page(newValue) {
      this.changePage(newValue)
    },
  },
  computed: {
    originLeftCount() {
      return Math.ceil(this.min / 2)
    },
    originRightCount() {
      return Math.floor(this.min / 2)
    },
    // 包含此頁數左側的頁數點擊後都維持 origin mode
    maxLeftPageKeepOrigin() {
      // 第幾個 目前是左側最右邊那個以外 所以去除一個
      const leftTargetNum = this.originLeftCount - 1
      // 要包含第一個所以減 1
      const pageTarget = START_PAGE + leftTargetNum - 1
      return pageTarget
    },
    // 包含此頁數右側的頁數點擊後都維持 origin mode
    minRightPageKeepOrigin() {
      // 倒數第幾個 目前是右側最左邊那個以外 所以去除一個
      const rightTargetNum = this.originRightCount - 1
      // 要包含最後一個所以加 1
      const pageTarget = this.totalPage - rightTargetNum + 1
      return pageTarget
    },
    isCenterMode() {
      return (
        this.currentPage > this.maxLeftPageKeepOrigin &&
        this.currentPage < this.minRightPageKeepOrigin
      )
    },
    endPage() {
      return this.totalPage - START_PAGE + 1
    },
    allPages() {
      // 生成結果不包含 end
      // 總共要 total 頁, 但不包含 0, 從一開始要加一
      const endRange = this.totalPage + 1
      return _range(START_PAGE, endRange)
    },
    // 最開始的顯示頁數 左右兩邊頁數+中間省略號
    pageOriginModeViewModels() {
      const viewModels = []
      // 生成結果不包含 end
      const endRange = this.totalPage + 1
      const leftPages = _range(START_PAGE, this.originLeftCount + 1)
      const rightPages = _range(endRange - this.originRightCount, endRange)

      viewModels.push(...leftPages)
      viewModels.push(ELLIPSIS)
      viewModels.push(...rightPages)

      return viewModels
    },
    // 點擊到省略號左右旁邊的頁數後，變為 左右各一開始與結尾頁數 然後向中間有省略號 在最中間則是多頁供點擊
    pageCenterModeViewModels() {
      const viewModels = []
      // 中間以外一邊有幾個
      const oneSideNum = (CENTER_PAGE_COUNT - 1) / 2
      const centerPages = _range(
        this.currentPage - oneSideNum,
        this.currentPage + oneSideNum + 1
      )
      viewModels.push(START_PAGE)
      viewModels.push(ELLIPSIS)
      viewModels.push(...centerPages)
      viewModels.push(ELLIPSIS)
      viewModels.push(this.endPage)

      return viewModels
    },
    pageDisPlayViewModels() {
      if (this.isCenterMode) {
        return this.pageCenterModeViewModels
      }
      return this.pageOriginModeViewModels
    },
  },
  methods: {
    // 確保格式統一
    makePageViewModel({ page, clickType }) {
      return { page, clickType }
    },
    changePage(page) {
      if (page === this.currentPage || page === ELLIPSIS) {
        return
      }
      this.currentPage = page
      this.$emit('changePage', page)
    },
    gotoPageOne() {
      this.currentPage = 1
    },
    goToNext() {
      if (this.currentPage === this.totalPage) {
        return
      }
      this.currentPage += 1
      this.$emit('changePage', this.currentPage)
    },
    goToPrevious() {
      if (this.currentPage === START_PAGE) {
        return
      }
      this.currentPage -= 1
      this.$emit('changePage', this.currentPage)
    },
  },
}
</script>
