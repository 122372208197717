var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"al-table_table-body_container bg-al-grey-100",style:({ height: _vm.height })},[_c('TableHeader',{attrs:{"columns":_vm.columns},scopedSlots:_vm._u([{key:"header-cell",fn:function(ref){
var column = ref.column;
var isId = ref.isId;
return [_vm._t("header-cell",null,{"column":column,"isId":isId})]}}],null,true)}),_c('TableSheet',{attrs:{"isLoading":_vm.isLoading,"columns":_vm.columns,"datas":_vm.datas,"height":_vm.sheetContainerHeight},scopedSlots:_vm._u([{key:"cell",fn:function(ref){
var column = ref.column;
var data = ref.data;
var isId = ref.isId;
return [_vm._t("cell",null,{"column":column,"data":data,"isId":isId})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }