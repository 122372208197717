<template>
  <div class="flex items-center justify-center">
    <v-tooltip bottom max-width="200px" v-bind="$attrs">
      <template #activator="{ on, attrs }">
        <v-icon
          :color="color"
          v-bind="attrs"
          v-on="on"
          :class="[iconClass, iconExtendClass]"
        >
          {{ icon }}
        </v-icon>
      </template>
      <AlText size="12" block>
        <slot></slot>
      </AlText>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'al-grey-500',
    },
    icon: {
      type: String,
      default: 'help',
    },
    iconClass: {
      type: [String, Object],
      default: '!text-12px',
    },
    iconExtendClass: {
      type: [String, Object],
      default: '',
    },
  },
}
</script>
