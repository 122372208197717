const state = {
  notifications: [],
  // 暫時先做單個訊息顯示，array 的集體通知樣式待時間充足後擴充
  /**
   * { text: '', icon: '', color?: string }
   */
  singleNotification: undefined,
}

const getters = {
  notifications: (state) => {
    return state.notifications || []
  },
  singleNotification: (state) => {
    return state.singleNotification
  },
}

const actions = {}

const mutations = {
  setSingleNotification(state, singleNotification) {
    state.singleNotification = singleNotification
  },
  // array 待擴充
  addNotification(state, notication) {
    state.notifications.push(notication)
  },
  shiftNotification(state) {
    state.notifications.shift()
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
