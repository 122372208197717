<template>
  <span :class="[wordBreakClass, textSizeClass, { block: block }]">
    <slot></slot>
  </span>
</template>
<script>
const textSizeEnum = {
  24: '4xl',
  22: '3xl',
  20: '2xl',
  18: 'xl',
  16: 'lg',
  14: 'base',
  12: 'xs',

  '4xl': '4xl',
  '3xl': '3xl',
  '2xl': '2xl',
  xl: 'xl',
  lg: 'lg',
  base: 'base',
  xs: 'xs',
}
export default {
  props: {
    size: {
      type: String,
      default: textSizeEnum.base,
    },
    block: {
      type: Boolean,
      default: false,
    },
    wordBreakClass: {
      type: String,
      default: 'al-text_break-word',
    },
  },
  computed: {
    textSizeClass() {
      const sizeClassSuffix = textSizeEnum[this.size] || textSizeEnum.base
      return `al-text-${sizeClassSuffix}`
    },
  },
}
</script>
<style lang="scss" scoped>
.al-text_break-word {
  word-break: break-word;
}
</style>
<style lang="scss">
.al-text-4xl {
  @include font('4xl');
}

.al-text-3xl {
  @include font('3xl');
}

.al-text-2xl {
  @include font('2xl');
}

.al-text-xl {
  @include font('xl');
}

.al-text-lg {
  @include font('lg');
}

.al-text-base {
  @include font('base');
}

.al-text-xs {
  @include font('xs');
}
</style>
