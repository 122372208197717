<template>
  <InputDefault
    v-bind="$attrs"
    @input="(target) => $emit('input', target)"
    :theme="themeName"
    :placeholder="placeholder"
    :label="label"
    :required="required"
    :helperText="helperText"
    :height="height"
    :inputEventFull="inputEventFull"
    :dense="dense"
    @blur="(e) => $emit('blur', e)"
    @focus="(e) => $emit('focus', e)"
    @keypress="(e) => $emit('keypress', e)"
    :inputExtendStyle="{
      paddingRight: suffixText || suffixIcon ? '46px' : undefined,
      paddingLeft: prefixIcon ? '46px' : undefined,
    }"
  >
    <template #label-suffix v-if="tooltipText">
      <DefaultVTooltip class="ml-4px">
        {{ tooltipText }}
      </DefaultVTooltip>
    </template>
    <template #input-decorate v-if="suffixText || suffixIcon">
      <AlText v-if="suffixText" size="14" class="absolute right-12px top-8px">
        {{ suffixText }}
      </AlText>
      <button
        v-else-if="suffixIcon"
        class="flex w-24px h-24px items-center justify-center absolute"
        :class="[
          suffixAction ? '' : 'cursor-default',
          dense ? 'right-12px top-8px' : 'right-12px top-12px',
        ]"
        @click="suffixAction() || undefined"
      >
        <v-icon size="16">
          {{ suffixIcon }}
        </v-icon>
      </button>
      <div
        v-if="prefixIcon"
        class="flex w-24px h-24px items-center justify-center absolute"
        :class="
          dense ? 'left-12px top-8px' : 'left-12px top-12px',
        "
      >
        <v-icon size="16">
          {{ prefixIcon }}
        </v-icon>
      </div>
    </template>
  </InputDefault>
</template>
<script>
import DefaultVTooltip from '@/components/global/c-vuetify/tooltip/DefaultVTooltip'
import InputDefault from '@/components/global/base/input/InputDefault'
import typeEnum from '@/components/global/base/input/InputDefault/typeEnum'
const { theme: themeEnum } = typeEnum
export default {
  inheritAttrs: false,
  components: {
    InputDefault,
    DefaultVTooltip,
  },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    // 以下的 props 都只是為了暴露子組件屬性給編輯器可以提示無實際作用
    // 因此都取消預設值避免子組件修改預設值需要修改父組件
    // 另外若子組件有新增 props 這裡沒更新不會出錯，只是編輯器不會智能提示。
    // !!important: 子組件的 theme 因為在這組件包裝過因此不特別暴露出去
    placeholder: {
      type: String,
      default: undefined,
    },
    // 上方標題
    label: {
      type: String,
      default: undefined,
    },
    // 是否必填（是的話若有標題，標題旁多出紅色星號）
    required: {
      type: Boolean,
      default: undefined,
    },
    // 幫助文字（提示 or 錯誤訊息）
    helperText: {
      type: String,
      default: undefined,
    },
    // 若為 string 則直接使用，若為 number 則默認為 px 數值.
    height: {
      type: [String, Number],
      default: undefined,
    },
    /**
     * true: 執行 onInput 時傳入整個 event 參數.
     * false: 執行 onInput 時只傳入值（event.target.value）當參數
     */
    inputEventFull: {
      type: Boolean,
      default: undefined,
    },
    // 依賴於 vuetify
    prefixIcon: {
      type: String,
      default: '',
    },
    suffixText: {
      type: String,
      default: undefined,
    },
    // 依賴於 vuetify
    suffixIcon: {
      type: String,
      default: '',
    },
    suffixAction: {
      type: Function,
      default: undefined,
    },
    tooltipText: {
      type: String,
      default: undefined,
    },
    // 高密度版本 （縮小高度）
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themeName() {
      if (this.isError) return themeEnum.error
      return themeEnum.default
    },
  },
}
</script>
