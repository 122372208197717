// axios
import axios from 'axios'

const axiosInstance = axios.create({
  withCredentials: true,
  // You can add your headers here
})

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error.response)
  }
)
export default axiosInstance
