import store from '@/store'

export const NOTIFICATION_ANIMATION_TIME_MS = 6000
// 建議資料存活時間不小於動畫時間，在畫面消失後再移除
export const NOTIFICATION_EXIST_TIME_MS = 8000

let singleNotificationTimeout = undefined

export const showNotification = (notification) => {
  store.commit('notification/addNotification', notification)
  setTimeout(() => {
    store.commit('notification/shiftNotification')
  }, NOTIFICATION_EXIST_TIME_MS)
}
// 暫時先做單個訊息顯示，array 的集體通知樣式待時間充足後擴充
/**
 *
 * @param {*} singleNotification { text: '' }
 */
export const showSingleNotification = (singleNotification) => {
  store.commit('notification/setSingleNotification', undefined)
  clearTimeout(singleNotificationTimeout)
  singleNotificationTimeout = setTimeout(() => {
    store.commit('notification/setSingleNotification', singleNotification)
  }, 100)
  // store.commit('notification/setSingleNotification', singleNotification)
  // singleNotificationTimeout = setTimeout(() => {
  //   store.commit('notification/setSingleNotification', undefined)
  // }, NOTIFICATION_EXIST_TIME_MS)
}

export const destroySingleNotification = () => {
  store.commit('notification/setSingleNotification', undefined)
  clearTimeout(singleNotificationTimeout)
}
