import { getUrlPrefix, httpApiRequestDefault } from './service'

/**
 * 
 * @param {*} param0 
 * @returns response 
 * {
    "id": "1062533843002589287",
    "name": "大白",
    "enable": true,
    "account": "white",
    "permissions": ["xxxx", "yyyy"]
}
 */
export const getActiveUser = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/me`,
    requestBody,
  })
}

/**
 * 
 * @param {*} param0 
 * @returns response 
 * [
    {
        "id": "3212132",
        "name": "測試帳號",
        "account": "jijijia",
        "enable": true,
        "role_ids": [
            "1"
        ],
        "created_at": "2020-08-12T08:51:35.72Z",
        "last_logined_at": "2021-09-28T16:32:36.957Z"
    }
]
 */
export const getAdmins = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/admins`,
    requestBody,
  })
}
/**
 * 
 * @param createAdmin( {data:{
    "name": "用戶III",
    "account": "abcd1234000e0",
    "enable": true,
    "role_ids": [
        "1","2"
    ]
  }
})
 * @returns 
 */
export const createAdmin = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/admin/admins`,
    requestBody,
  })
}
/**
 * 
 * @param updateAdmin( data:{
    "name": "測試用戶",
    "account": "abcd",
    "enable": true,
    "role_ids": [
    ]
})
 * @returns 
 */
export const updateAdmin = ({ pathParams, ...requestBody } = {}) => {
  const { adminId } = pathParams
  return httpApiRequestDefault({
    method: 'put',
    url: `${getUrlPrefix()}/admin/admins/${adminId}`,
    requestBody,
  })
}
/**
 * 批次更新擁有特定角色的用戶
 * @param updateAdmin( {data:{
    "adminIds": []
}, pathParams: { roleId: '' }
})
 * @returns 
 */
export const updateRoleOfAdmins = ({ pathParams, ...requestBody } = {}) => {
  const { roleId } = pathParams
  return httpApiRequestDefault({
    method: 'put',
    url: `${getUrlPrefix()}/admin/roles/${roleId}/bulk`,
    requestBody,
  })
}

/**
 * 
 * @param updateAdmin( pathParams:{
    "adminId": ""
})
 * @returns 
 */
export const deleteAdmin = ({ pathParams, ...requestBody } = {}) => {
  const { adminId } = pathParams
  return httpApiRequestDefault({
    method: 'delete',
    url: `${getUrlPrefix()}/admin/admins/${adminId}`,
    requestBody,
  })
}

/**
 * 
 * @param {*} param0 optional: param: { limit:'25', offset:'0', search:'測試' }
 * @returns response 
 * [
     {
            "id": "222rrr222rrr",
            "name": "測試推廣人2",
            "email_prefix": "212rrr",
            "enable": true
        },
]
 */
export const getReferrers = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/referrers`,
    requestBody,
  })
}

/**
 * 
 * @param {*} param0 optional: param: { search:'測試' }
 * @returns response 
     {
        "total": "2"
    }
 */
export const getReferrersTotal = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/referrers_total`,
    requestBody,
  })
}

/**
 * 
 * @param {*} createReferrers({
 *  data: {
    "name":"123",
    "email_prefix":"white",
    "enable":true
  }
 * })
 * @returns 
 */
export const createReferrer = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/admin/referrers`,
    requestBody,
  })
}

/**
 * 
 * @param {*} createReferrers({
 *  pathParams: { referrerId: '' },
 *  data: {
    "name":"666",
    "enable":true
}
 * })
 * @returns 
 */
export const updateReferrer = ({ pathParams, ...requestBody } = {}) => {
  const { referrerId } = pathParams
  return httpApiRequestDefault({
    method: 'put',
    url: `${getUrlPrefix()}/admin/referrers/${referrerId}`,
    requestBody,
  })
}

/**
 * 
 * @param {*} param0 optional: param: { limit:'25', offset:'0', search:'測試' }
 * @returns response 
 * [
     {
            "id": "222rrr222rrr",
            "name": "2",
            "email_prefix": "212rrr",
            "enable": true
        },
]
 */
export const getSalespeople = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/salespeople`,
    requestBody,
  })
}

/**
 * 
 * @param {*} param0 optional: param: { search:'測試' }
 * @returns response 
     {
        "total": "2"
    }
 */
export const getSalespeopleTotal = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/salespeople_total`,
    requestBody,
  })
}

/**
 * 
 * @param {*} createReferrers({
 *  data: {
    "name":"123",
    "email_prefix":"white",
    "enable":true
  }
 * })
 * @returns 
 */
export const createSalesperson = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/admin/salespeople`,
    requestBody,
  })
}

/**
 * 
 * @param {*} createReferrers({
 *  pathParams: { salespeopleId: '' },
 *  data: {
    "name":"666",
    "enable":true
}
 * })
 * @returns 
 */
export const updateSalesperson = ({ pathParams, ...requestBody } = {}) => {
  const { salespeopleId } = pathParams
  return httpApiRequestDefault({
    method: 'put',
    url: `${getUrlPrefix()}/admin/salespeople/${salespeopleId}`,
    requestBody,
  })
}

/**
 * enable 全選不要帶 key
 * @param {*} param0 optional: param: { limit:'25', offset:'0', search:'測試', referrerId: '', enable: false, salespersonId: '' }
 * @returns response 
 * {
 *  users: [
 *    {
            "user_id": "112221",
            "account": "1@qimin.com",
            "name": "1",
            "email": "1@kyc.com",
            "phone": "1",
            "enable": false,
            "referrer": {
                "id": "122121",
                "name": "測試推廣人2",
                "email_prefix": "referrer_2",
                "enable": true
            },
            "salesperson": {
                "id": "23321132221",
                "name": "123",
                "email_prefix": "white",
                "enable": true
            }
        },
 *  ]
 * }
 */
export const getInvestors = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users`,
    requestBody,
  })
}
/**
 * enable 全選不要帶 key
 * @param {*} param0 getInvestor(
 * {
 *  pathParams: { investorId: '' },
 * })
 *  optional: param: { limit:'25', offset:'0', search:'測試', referrerId: '', enable: false, salespersonId: '' }
 * @returns response 
 * {
    "user_id": "112221",
    "account": "1@qimin.com",
    "name": "1",
    "email": "1@kyc.com",
    "phone": "1",
    "enable": false,
    "referrer": {
        "id": "122121",
        "name": "測試推廣人2",
        "email_prefix": "referrer_2",
        "enable": true
    },
    "salesperson": {
        "id": "23321132221",
        "name": "123",
        "email_prefix": "white",
        "enable": true
    }
 * }
*/
export const getInvestor = ({ pathParams, ...requestBody } = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}`,
    requestBody,
  })
}

/**
 * 
 * @param {*} updateInvestor({
 *  pathParams: { investorId: '' },
 *  data: {
    "salespersonId":"11111",
    "enable":false
}
 * })
 * @returns 
 */
export const updateInvestor = ({ pathParams, ...requestBody } = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'put',
    url: `${getUrlPrefix()}/admin/users/${investorId}`,
    requestBody,
  })
}
/**
 * 
 * @param {*} updateInvestor({
 *  pathParams: { investorId: '' },
 *  data: {
    "salespersonId":"11111",
}
 * })
 * @returns 
 */
export const updateInvestorSalesperson = ({
  pathParams,
  ...requestBody
} = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'put',
    url: `${getUrlPrefix()}/admin/users/${investorId}/salesperson`,
    requestBody,
  })
}

/**
 *
 * @param {*} getInvestorKyc({
 *  pathParams: { investorId: '' }
 * })
 * @returns response
 */
export const getInvestorKyc = ({ pathParams, ...requestBody } = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}/kyc`,
    requestBody,
  })
}

/**
 *
 * @param {*} getInvestorIdentification({
 *  pathParams: { investorId: '' }
 * })
 * @returns response
 */
export const getInvestorIdentification = ({
  pathParams,
  ...requestBody
} = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}/identification`,
    requestBody,
  })
}

/**
 *
 * @param {*} getInvestorBankAccount({
 *  pathParams: { investorId: '' }
 * })
 * @returns response
 */
export const getInvestorBankAccount = ({ pathParams, ...requestBody } = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}/bank_account`,
    requestBody,
  })
}

/**
 *
 * @param {*} getInvestorContract({
 *  pathParams: { investorId: '' }
 * })
 * @returns response
 */
export const getInvestorContract = ({ pathParams, ...requestBody } = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}/contract`,
    requestBody,
  })
}

/**
 *
 * @param {*} getInvestorRiskQuestionnaire({
 *  pathParams: { investorId: '' }
 * })
 * @returns response
 */
export const getInvestorRiskQuestionnaire = ({
  pathParams,
  ...requestBody
} = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}/risk_questionnaire`,
    requestBody,
  })
}

/**
 *
 * @param {*} getInvestorApplySteps({
 *  pathParams: { investorId: '' }
 * })
 * @returns response {
  "user_id": "9213809328302",
  "prepare": "FINISHED",
  "personal_info": "FINISHED",
  "identification": "YET",
  "bank_account": "YET",
  "contract": "YET",
  "risk_survey": "YET",
  "updated_at": "2022-09-02T10:57:08.06Z",
  "personal_info_reason": "dd",
  "identification_reason": "",
  "bank_account_reason": "",
  "contract_reason": "",
  "risk_survey_reason": ""
}
 */
export const getInvestorApplySteps = ({ pathParams, ...requestBody } = {}) => {
  const { investorId } = pathParams
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/users/${investorId}/apply_steps`,
    requestBody,
  })
}
