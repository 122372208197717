<template>
  <div class="al-table_table-body_container bg-al-grey-100" :style="{ height }">
    <TableHeader :columns="columns">
      <template #header-cell="{ column, isId }">
        <slot name="header-cell" :column="column" :isId="isId"></slot>
      </template>
    </TableHeader>
    <TableSheet
      :isLoading="isLoading"
      :columns="columns"
      :datas="datas"
      :height="sheetContainerHeight"
    >
      <template #cell="{ column, data, isId }">
        <slot name="cell" :column="column" :data="data" :isId="isId"></slot>
      </template>
    </TableSheet>
  </div>
</template>
<script>
import TableHeader from './TableHeader'
import TableSheet from './TableSheet'
export default {
  components: { TableHeader, TableSheet },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: undefined,
    },
    sheetContainerHeight: {
      type: String,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.al-table_table-body_container {
  /* 486 + 2 (border) */
  height: 488px;
  width: 100%;
  border-top: 1px solid $color-grey-400;
  border-bottom: 1px solid $color-grey-400;
}
</style>
