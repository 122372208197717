import { render, staticRenderFns } from "./AlDivider.vue?vue&type=template&id=95a9771c&scoped=true&"
var script = {}
import style0 from "./AlDivider.vue?vue&type=style&index=0&id=95a9771c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95a9771c",
  null
  
)

export default component.exports