import { getUrlPrefix, httpApiRequestDefault } from './service'

/**
 *
 * @param {*} param0 login({ data: { username: '', password: '' } })
 * @returns
 */
export const login = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/admin/login`,
    // url: `${getUrlPrefix()}/user/auth/login`,
    requestBody,
  })
}
/**
 *
 * @param {*} noParam
 * @returns
 */
export const logout = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    // url: `${getUrlPrefix()}/user/auth/logout`,
    url: `${getUrlPrefix()}/admin/logout`,
    requestBody,
  })
}
/**
 *
 * @param {*} noParam
 * @returns
 */
export const getCheckLogin = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/admin/check_login`,
    requestBody,
  })
}
/**
 * 定期更改密碼須先通過登入，忘記密碼需先打前二驗證碼。
 * checkUserEmailExist({ query: { email: '' } })
 *
 * @returns result { exist: Boolean }
 */
export const checkUserEmailExist = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'get',
    url: `${getUrlPrefix()}/user/user_check/email`,
    requestBody,
  })
}

/**
 *
 * @param {*} param0 enterEmailVerifyCode({ data: { token: '' } })
 * @returns
 */
export const enterEmailVerifyCode = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/pw_verify`,
    requestBody,
  })
}
/**
 *
 * @param {*} param0 sendEmailVerifyCode({ data: { email: '' } })
 * @returns
 */
export const sendEmailVerifyCode = ({ pathParams, ...requestBody } = {}) => {
  return httpApiRequestDefault({
    method: 'post',
    url: `${getUrlPrefix()}/user/auth/pw_token`,
    requestBody,
  })
}
