<template>
  <div class="breadcrumbs-container">
    <template v-if="items.length">
      <AlText size="12" block>
        <router-link v-if="items[0].to" :to="items[0].to">
          {{ items[0].text }}
        </router-link>
        <span v-else>{{ item[0].text }}</span>
      </AlText>
      <template v-for="(item, index) in items">
        <template v-if="index !== 0">
          <v-icon
            class="ml-8px"
            :key="`breadcrumbs-chevron-${index}`"
            size="16px"
          >
            chevron_right
          </v-icon>
          <AlText
            class="ml-8px"
            size="12"
            :key="`breadcrumbs-text-${index}`"
            block
          >
            <router-link v-if="item.path" :to="item.path">
              {{ item.text }}
            </router-link>
            <span v-else>{{ item.text }}</span>
          </AlText>
        </template>
      </template>
    </template>
  </div>
</template>
<script>
export default {
  // to: https://book.vue.tw/CH4/4-3-router-link.html#to-%E5%B1%AC%E6%80%A7
  // items: [{ to: RouterLink to, text: string }]
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.breadcrumbs-container {
  display: flex;
  justify-content: left;
  align-items: center;
}
</style>
