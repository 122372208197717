import Vue from 'vue'
import VueRouter from 'vue-router'

import main from './modules/main'
import full from './modules/full'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "layout-main-header" */ '@/layouts/layout-main-header'
      ),
    children: [
      // header with sidebar 樣式
      ...main,
      // {
      //   path: '/',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "layout-main-side" */ '@/layouts/layout-main-side'
      //     ),
      //   children: [...main, ...mainHeader],
      // },
    ],
  },
  {
    path: '',
    component: () =>
      import(/* webpackChunkName: "layout-full" */ '@/layouts/layout-full'),
    children: [...full],
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "layout-full" */ '@/layouts/layout-full'),
    children: [
      {
        path: '/',
        name: 'error-default',
        meta: {
          title: '發生錯誤',
          isPublic: true,
        },
        component: () =>
          import(/* webpackChunkName: "page-error" */ '@/views/error.vue'),
      },
      // this page just for go to error page clearly
      {
        path: '/error',
        name: 'error',
        meta: {
          title: '發生錯誤',
          isPublic: true,
        },
        component: () =>
          import(/* webpackChunkName: "page-error" */ '@/views/error.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export default router
