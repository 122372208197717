<template>
  <div class="al-table_table-sheet_container" :style="{ height }">
    <template v-if="columns.length">
      <div
        v-for="(data, dataIndex) in datas"
        :key="`al-table_table-sheet_row-${dataIndex}`"
        class="al-table_table-sheet_row"
      >
        <div
          v-for="(column, columnIndex) in columns"
          :key="`al-table_table-sheet_cell-${dataIndex}-${columnIndex}`"
          class="al-table_table-sheet_cell"
          :style="{ minWidth: column.width }"
        >
          <slot
            name="cell"
            :column="column"
            :data="data"
            :isId="isId(column.id)"
          >
            <AlText class="text-al-grey-800" size="12">
              {{ column.getValue(data, dataIndex) }}
            </AlText>
          </slot>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { isId } from '../alTableUtils'
import AlText from '@/components/global/also-ui/AlText'
export default {
  components: { AlText },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    datas: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: undefined,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: { isId },
}
</script>

<style scoped>
.al-table_table-sheet_container {
  height: 440px;
  min-width: 100%;
  overflow: auto;
}
.al-table_table-sheet_row {
  height: 56px;
  min-width: 100%;
  display: flex;
}
.al-table_table-sheet_cell {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 16px;
}
</style>
