<template>
  <div class="flex justify-center items-center p-2px">
    <v-icon v-bind="$attrs" :class="iconClass"><slot></slot></v-icon>
  </div>
</template>
<script>
export default {
  props: {
    iconClass: {
      type: String,
      default: '',
    },
  },
}
</script>
