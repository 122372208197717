<template>
  <div class="flex">
    <button
      class="al-table_pagination-default_button mr-5px al-table_pagination-default_hover-button"
      @click="goToPrevious"
    >
      <div class="flex p-2px items-center justify-center">
        <v-icon size="20" class="!text-inherit">chevron_left</v-icon>
      </div>
    </button>
    <div class="al-table_pagination-default_page-container">
      <button
        v-for="pageNum in allPages"
        :key="`pagination-number-1-${pageNum}`"
        class="al-table_pagination-default_button al-table_pagination-default_hover-text"
        :class="{
          'al-table_pagination-default_current-page': pageNum === currentPage,
        }"
        @click="changePage(pageNum)"
      >
        {{ pageNum }}
      </button>
    </div>
    <button
      class="al-table_pagination-default_button ml-5px al-table_pagination-default_hover-button"
      @click="goToNext"
    >
      <div class="flex p-2px items-center justify-center">
        <v-icon size="20" class="!text-inherit">chevron_right</v-icon>
      </div>
    </button>
  </div>
</template>
<script>
// _range(1,3) will return [1,2]
import _range from 'lodash/range'

const START_PAGE = 1
export default {
  props: {
    totalPage: {
      type: Number,
      default: 1,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    page(newValue) {
      this.changePage(newValue)
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    endIndex() {
      return this.totalPage - 1
    },
    allPages() {
      // 總共要 total 頁, 但不包含 0, 從一開始要加一
      const endRange = this.totalPage + 1
      return _range(START_PAGE, endRange)
    },
  },
  methods: {
    changePage(page) {
      if (this.currentPage === page) {
        return
      }
      this.currentPage = page
      this.$emit('changePage', page)
    },
    gotoPageOne() {
      this.currentPage = 1
    },
    goToNext() {
      if (this.currentPage === this.totalPage) {
        return
      }
      this.currentPage += 1
      this.$emit('changePage', this.currentPage)
    },
    goToPrevious() {
      if (this.currentPage === START_PAGE) {
        return
      }
      this.currentPage -= 1
      this.$emit('changePage', this.currentPage)
    },
  },
}
</script>
