import { getActiveUser } from '@/network/httpApi/user'
import { logout } from '@/network/httpApi/auth'

const state = {
  /**
   * {
    "userId": "1062533843002589287",
    "account": "testuser@otsogroup.com",
    "name": "大白",
    "referrerId": "0",
    "enable": true,
    "email": "white@otsogroup.com",
    "phone": ""
}
   */
  activeUser: undefined,
}

const getters = {
  activeUser: (state) => {
    return state.activeUser || {}
  },
}

const actions = {
  async initActiveUser({ commit }) {
    const activeUser = await getActiveUser()
    commit('setActiveUser', activeUser)
  },
  async logout({ commit }) {
    await logout()
    commit('setActiveUser', undefined)
  },
}

const mutations = {
  setActiveUser(state, user) {
    state.activeUser = user
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
