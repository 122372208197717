import { middlewareUpdate } from '@/middleware/router/auth'
import { middlewarePageTitle } from '@/middleware/router/basic'

// 基本上不需修改 middlewareRouter，提供給所有鉤子共用
/**
 * 將會照順序執行 middlewares 的每一個 middleware，當 middleware 回傳為 undefined 或是 true 時視為通過，
 * 會接續執行下一個，直到所有 middleware 皆為通過則執行 routerParam 的 next()。
 * 若其中有 middleware 不通過（回傳 false）或是回傳路由導向（非 boolean 或 undefined 視為導向），
 * 則中斷 middleware 執行，並執行 next(false) 或 next(導向目標)。
 * @param {*} routerParam routerParam(now has {to, from, next}) is vue router guard's params
 * @param {Array} middlewares middlewares need to execute
 * @returns
 */
const middlewareRouter = async ({ routerParam, middlewares }) => {
  const { to, from, next } = routerParam
  for (let index = 0; index < middlewares.length; index++) {
    const currentMiddleware = middlewares[index]
    const result = await currentMiddleware({ to, from })
    // middleware 通過
    if (result === true || result === undefined) {
      continue
    }
    // middleware 不通過 或是 回傳導向新目標
    next(result)
    return
  }
  next()
}
// 若有新路由守衛需要掛，可以參照這個開新 function，
// 只需要修改使用到的 middlewares（記得留意是照陣列順序執行）
const middlewareRouterBeforeEach = (to, from, next) => {
  const middlewaresBeforeEach = to.meta?.middleware?.middlewaresBeforeEach || []
  const middlewares = [
    middlewareUpdate,
    middlewarePageTitle,
    ...middlewaresBeforeEach,
  ]
  middlewareRouter({ routerParam: { to, from, next }, middlewares })
}

export { middlewareRouterBeforeEach }
