<template>
  <DefaultVDialog @input="(e) => $emit('input', e)" v-bind="$attrs">
    <AlText class="font-semibold text-al-grey-800 text-left" size="16" block>
      {{ title }}
    </AlText>
    <AlText class="text-al-grey-800 mt-24px text-left" size="14" block>
      {{ text }}
    </AlText>
    <div class="flex justify-end mt-24px">
      <ThemeVBtn @click="$emit('confirm')">確認</ThemeVBtn>
    </div>
  </DefaultVDialog>
</template>
<script>
import DefaultVDialog from '@/components/global/c-vuetify/dialog/DefaultVDialog'
export default {
  components: {
    DefaultVDialog,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
