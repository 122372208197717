<template>
  <v-dialog
    v-bind="$attrs"
    :value="value"
    @input="(e) => $emit('input', e)"
    :content-class="`${defaultContentClass} ${contentClass} ${
      largePadding ? 'py-32px px-48px ' : 'py-24px px-32px '
    }`"
  >
    <button
      class="absolute right-16px top-16px !w-24px !h-24px flex justify-center items-center"
      style="padding: 3px"
      @click="$emit('input', false)"
    >
      <v-icon size="18">clear</v-icon>
    </button>
    <AlText
      v-if="title"
      class="font-semibold text-al-grey-800 text-left"
      size="16"
      block
    >
      {{ title }}
    </AlText>
    <slot></slot>
  </v-dialog>
</template>
<script>
export default {
  props: {
    // default class on dialog, send it will override default content-class
    defaultContentClass: {
      type: String,
      default: 'bg-al-grey-100 !rounded-8px relative text-center',
    },
    // class on dialog, it will append with default content-class
    contentClass: {
      type: String,
      default: '',
    },
    // it is for v-model, control dialog opening
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    largePadding: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
