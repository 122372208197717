// import userConfig from '@/config/user'
// const {
//   permissionEnum: { PERMISSION_FAKE },
// } = userConfig

// header with sidebar 樣式
const layoutComponent = () =>
  import(
    /* webpackChunkName: "layout-main-side" */ '@/layouts/layout-main-side'
  )
const emptyComponent = () =>
  import(/* webpackChunkName: "layout-empty" */ '@/layouts/layout-empty')
export default [
  {
    path: '/',
    component: layoutComponent,
    children: [
      {
        path: '',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "page-index" */ '@/views/index.vue'),
        meta: {
          title: '首頁',
        },
      },
    ],
  },
  // meta: {
  //   permission: {
  //     [PERMISSION_FAKE]: true,
  //   },
  // },
  {
    path: '/permission-manage',
    name: 'permission-manage',
    component: layoutComponent,
    children: [
      {
        path: 'role',
        name: 'permission-manage-role',
        component: () =>
          import(
            /* webpackChunkName: "page-permission-manage-role" */ '@/views/permission-manage/role.vue'
          ),
        meta: {
          title: '角色管理',
        },
      },
      {
        path: 'account',
        name: 'permission-manage-account',
        component: () =>
          import(
            /* webpackChunkName: "page-permission-manage-account" */ '@/views/permission-manage/account.vue'
          ),
        meta: {
          title: '帳號管理',
        },
      },
    ],
  },

  {
    path: 'contract-manage',
    component: layoutComponent,
    children: [
      {
        path: '',
        name: 'contract-manage',
        component: () =>
          import(
            /* webpackChunkName: "page-contract-manage" */ '@/views/contract-manage'
          ),
        meta: {
          title: '簽署文件管理',
        },
      },
      {
        path: 'create',
        name: 'contract-manage-create',
        component: () =>
          import(
            /* webpackChunkName: "page-contract-manage-create" */ '@/views/contract-manage/create'
          ),
        meta: {
          title: '簽署文件管理-新增文件',
        },
      },
    ],
  },
  {
    path: '/promote-manage',
    name: 'promote-manage',
    component: layoutComponent,
    children: [
      {
        path: 'referrer',
        name: 'promote-manage-referrer',
        component: () =>
          import(
            /* webpackChunkName: "page-promote-manage-referrer" */ '@/views/promote-manage/referrer.vue'
          ),
        meta: {
          title: '推薦人管理',
        },
      },
      {
        path: 'salespeople',
        name: 'promote-manage-salespeople',
        component: () =>
          import(
            /* webpackChunkName: "page-promote-manage-salespeople" */ '@/views/promote-manage/salespeople.vue'
          ),
        meta: {
          title: '業務員管理',
        },
      },
    ],
  },
  {
    path: '/investor-manage',
    component: layoutComponent,
    children: [
      {
        path: '',
        name: 'investor-manage',
        component: () =>
          import(
            /* webpackChunkName: "page-investor-manage" */ '@/views/investor-manage'
          ),
        meta: {
          title: '客戶管理',
        },
      },
      {
        path: ':id',
        name: 'investor-manage-_id',
        component: () =>
          import(
            /* webpackChunkName: "page-investor-manage-_id'" */ '@/views/investor-manage/_id'
          ),
        meta: {
          title: '客戶管理',
        },
      },
    ],
  },
  {
    path: '/withdraw-orders',
    component: layoutComponent,
    children: [
      {
        path: '',
        name: 'withdraw-orders',
        component: () =>
          import(
            /* webpackChunkName: "page-withdraw-orders" */ '@/views/withdraw-orders.vue'
          ),
        meta: {
          title: '出金紀錄',
        },
      },
    ],
  },
  {
    path: '/audit-manage',
    component: layoutComponent,
    children: [
      {
        path: 'open-account',
        component: emptyComponent,
        children: [
          {
            path: '',
            name: 'audit-manage-open-account',
            component: () =>
              import(
                /* webpackChunkName: "page-audit-manage-open-account" */ '@/views/audit-manage/open-account'
              ),
            meta: {
              title: '開戶資料審核列表',
            },
          },
          {
            path: ':id',
            name: 'audit-manage-open-account-_id',
            component: () =>
              import(
                /* webpackChunkName: "page-audit-manage-open-account-_id'" */ '@/views/audit-manage/open-account/_id'
              ),
            meta: {
              title: '開戶資料審核',
            },
          },
        ],
      },
      {
        path: 'trader',
        component: emptyComponent,
        children: [
          {
            path: '',
            name: 'audit-manage-trader',
            component: () =>
              import(
                /* webpackChunkName: "page-audit-manage-trader" */ '@/views/audit-manage/trader'
              ),
            meta: {
              title: '交易帳號審核列表',
            },
          },
          {
            path: ':id',
            name: 'audit-manage-trader-_id',
            component: () =>
              import(
                /* webpackChunkName: "page-audit-manage-trader-_id'" */ '@/views/audit-manage/trader/_id'
              ),
            meta: {
              title: '交易帳號審核',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/holiday',
    component: layoutComponent,
    children: [
      {
        path: '',
        name: 'holiday',
        component: () =>
          import(/* webpackChunkName: "page-holiday" */ '@/views/holiday.vue'),
        meta: {
          title: '假日檔管理',
        },
      },
    ],
  },
]
