<template>
  <v-switch
    v-model="tempInputValue"
    v-bind="$attrs"
    boolean
    @change="(e) => $emit('input', e)"
    @click="(e) => $emit('click', e)"
  />
</template>
<script>
export default {
  props: {
    // type is any
    inputValue: {
      default: false,
    },
  },
  watch: {
    tempInputValue() {
      this.$nextTick(() => {
        this.tempInputValue = this.inputValue
      })
    },
    inputValue(newValue) {
      this.$nextTick(() => {
        this.tempInputValue = newValue
      })
    },
  },
  data() {
    return {
      tempInputValue: false,
    }
  },
  mounted() {
    this.tempInputValue = this.inputValue
  },
}
</script>
