<template>
  <!-- this div is for adding class, because use important p-0 m-0 on checkbox -->
  <div>
    <v-checkbox
      v-bind="{ ...$attrs, ...expendProp }"
      class="!p-0 !m-0"
      :inputValue="inputValue"
      :value="value"
      :checked="value"
      @change="(e) => $emit('input', e)"
      @click="(e) => $emit('click', e)"
      dense
    >
      <template #label>
        <AlText :class="['text-al-grey-700', labelExtendClass]" size="14">
          {{ label }}
        </AlText>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input',
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    inputValue: {
      default: null,
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    /**
     * value is Boolean, true/false
     */
    boolean: {
      type: Boolean,
      default: false,
    },
    labelExtendClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    expendProp() {
      return this.boolean ? { 'true-value': true, 'false-value': false } : {}
    },
  },
}
</script>
